import axios from "axios";
import httpClient from "@/api/httpClient";

const LOGIN = "/api/auth/signin";
const USER = "/api/auth/user";
const REFRESH_TOKEN = "/api/auth/refresh-token";

/**
 *
 * @param {*} _email
 * @param {*} _password
 *
 * Authentication using Email
 */
const clientLogin = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 7000,
});

const login = _payload => clientLogin.post(LOGIN, _payload, {
    headers: {
        "Content-Type": "multipart/form-data"
    }
});

const user = () => httpClient.get(USER);
const refreshToken = () => clientLogin.post(REFRESH_TOKEN, {
    "refresh_token": localStorage.getItem("refresh_token")
})

export {login, refreshToken, user};
