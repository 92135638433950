var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.auth},[_vm._m(0),_c('div',{staticClass:"pl-5 pr-5 pt-5 pb-5 bg-white text-center",class:_vm.$style.container},[_c('div',{staticClass:"text-dark font-size-30 mb-2 text-left"},[_vm._v(" Masuk ke Dolarindo Connect ")]),_c('div',{staticClass:"text-muted text-left mb-4"},[_vm._v(" Hanya untuk admin Dolarindo Connect ")]),_c('a-form',{staticClass:"mb-4",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                  {
                    type: 'email',
                    message: 'Email tidak sesuai'
                  },
                  {
                    required: true,
                    message: 'Email tidak boleh kosong!'
                  }
                  ]
            }
          ]),expression:"[\n            'email',\n            {\n              rules: [\n                  {\n                    type: 'email',\n                    message: 'Email tidak sesuai'\n                  },\n                  {\n                    required: true,\n                    message: 'Email tidak boleh kosong!'\n                  }\n                  ]\n            }\n          ]"}],attrs:{"size":"large","placeholder":"Email"}})],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                { required: true, message: 'Password tidak boleh kosong!' }
              ]
            }
          ]),expression:"[\n            'password',\n            {\n              rules: [\n                { required: true, message: 'Password tidak boleh kosong!' }\n              ]\n            }\n          ]"}],attrs:{"size":"large","placeholder":"Password","type":"password"}})],1),_c('a-button',{staticClass:"text-center btn btn-primary w-100 font-weight-bold font-size-18",attrs:{"type":"primary","htmlType":"submit","size":"large"}},[_vm._v("Masuk ")])],1),_c('router-link',{staticClass:"text-blue font-weight-bold font-size-18",attrs:{"to":"/auth/forgot-password"}},[_c('div',{staticClass:"mt-3"},[_vm._v("Lupa password?")])])],1),_vm._m(1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-5 pb-5 d-flex align-items-end mt-10"},[_c('div',{staticClass:"air__utils__logo__text"},[_c('div',{staticClass:"air__utils__logo__name text-uppercase mb-2 text-center text-dark font-size-21"},[_c('img',{attrs:{"src":"resources/logo-dolarindo.png","width":"100","alt":"Dolarindo"}})])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-auto pb-5 pt-5"},[_c('div',{staticClass:"text-gray-4 text-center"},[_vm._v(" Designed by Dolarindo ")])])}]

export { render, staticRenderFns }