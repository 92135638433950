<template>
  <div :class="$style.auth">
    <div class="pt-5 pb-5 d-flex align-items-end mt-10">
      <div class="air__utils__logo__text">
        <div
            class="air__utils__logo__name text-uppercase mb-2 text-center text-dark font-size-21"
        >
          <img src="resources/logo-dolarindo.png" width="100" alt="Dolarindo"/>
        </div>
        <!--        <div-->
        <!--            class="air__utils__logo__descr text-uppercase font-size-12 text-gray-6 text-center"-->
        <!--        >-->
        <!--          Dolarindo Admin-->
        <!--        </div>-->
      </div>
    </div>
    <div
        class="pl-5 pr-5 pt-5 pb-5 bg-white text-center"
        :class="$style.container"
    >
      <div class="text-dark font-size-30 mb-2 text-left">
        Masuk ke Dolarindo Connect
      </div>
      <div class="text-muted text-left mb-4">
        Hanya untuk admin Dolarindo Connect
      </div>
      <a-form class="mb-4" :form="form" @submit="handleSubmit">
        <a-form-item>
          <a-input
              size="large"
              placeholder="Email"
              v-decorator="[
              'email',
              {
                rules: [
                    {
                      type: 'email',
                      message: 'Email tidak sesuai'
                    },
                    {
                      required: true,
                      message: 'Email tidak boleh kosong!'
                    }
                    ]
              }
            ]"
          />

        </a-form-item>
        <a-form-item>
          <a-input-password
              size="large"
              placeholder="Password"
              type="password"
              v-decorator="[
              'password',
              {
                rules: [
                  { required: true, message: 'Password tidak boleh kosong!' }
                ]
              }
            ]"
          />
        </a-form-item>
        <a-button
            type="primary"
            htmlType="submit"
            size="large"
            class="text-center btn btn-primary w-100 font-weight-bold font-size-18"
        >Masuk
        </a-button>
      </a-form>
      <router-link
          to="/auth/forgot-password"
          class="text-blue font-weight-bold font-size-18"
      >
        <div class="mt-3">Lupa password?</div>
      </router-link>

    </div>

    <div class="mt-auto pb-5 pt-5">
      <div class="text-gray-4 text-center">
        Designed by Dolarindo
      </div>
    </div>
  </div>
</template>
<script>
import {login, user} from "@/api/authentication.api";
// import {mapMutations} from "vuex";

export default {
  name: "AirLogin",
  components: {},
  data: function () {
    return {
      form: this.$form.createForm(this),
      redirect: "/dashboard",
    };
  },
  created() {
    if (this.$route.query.redirect) this.redirect = this.$route.query.redirect;
  },
  methods: {
    // ...mapMutations(["UPDATE_USER"]),
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$nprogress.start();
          // const payload = {
          //   email: values.email,
          //   password: values.password,
          // };
          const bodyFormData = new FormData();
          bodyFormData.set('email', values.email)
          bodyFormData.set('password', values.password)
          this.doLogin(bodyFormData);
        }
      });
    },
    // async doLogin() {
    async doLogin(payload) {
      try {
        const response = await login(payload);
        //console.log("Response Profile: " + JSON.stringify(response))
        if (response.data.data.access_token) {
          const now = new Date();
          localStorage.setItem("token", response.data.data.access_token);
          localStorage.setItem("expires_in", response.data.data.expires_in);
          localStorage.setItem("expires_at", now.setSeconds(now.getSeconds() + (response.data.data.expires_in / 4)));
          localStorage.setItem("refresh_token", response.data.data.refresh_token);
          // get profile data
          var user_data = await this.getUserData();

          // check if this account is Super Admin
          if (user_data.data.role !== 1) {
            localStorage.setItem('USER', JSON.stringify(user_data.data))
            this.$notification["success"]({
              message: "Sukses",
              description: "Selamat Datang Kembali"
            });
            await this.$router.push(this.redirect);
          }
          else {
            // redirect to register business data
            this.$notification["warning"]({
              message: "Unauthorized",
              description: "Anda tidak diijinkan Masuk"
            });
            // Clear Token and Redirect to Home Page
            localStorage.clear();
          }
        }
      } catch (error) {
        // handle the error here
        this.$notification["warning"]({
          message: "Unauthorized",
          description: "Anda tidak diijinkan Masuk"
        });
        // Clear Token and Redirect to Home Page
        localStorage.clear();
      }
      this.$nprogress.done();
    },
    parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    },
    async getUserData() {
      const response = await user();
      return response;
    }
  }
};
</script>
<style lang="scss" module>
@import "@/components/system/Auth/style.module.scss";
</style>
